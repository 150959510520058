import { Typography } from "@mui/material";

export const H4 = (props) => {
  return (
    <Typography
      variant="h4"
      style={
        {
          // base style goes here
        }
      }
      {...props}
    />
  );
};
