import React from "react";
// icons & images
import Check from "../../assets/check.svg";

// Import custom components
import { Box } from "@mui/material";
import { SolidButton } from "../../ui/SolidButton";
import { SmallText } from "../../ui/SmallText";
import { H3 } from "../../ui/H3";

const AuthActionsSuccessState = ({ title, message, btnLabel, btnAction }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(0, 178, 255, 0.2)",
          border: "solid 8px #00B2FF",
          width: "150px",
          height: "150px",
          borderRadius: "50%",
          mt: 7,
        }}
      >
        <Box component="img" src={Check} />
      </Box>
      <H3
        sx={{
          mt: 7,
        }}
      >
        {title ? title : "Successful"}
      </H3>
      <SmallText
        sx={{
          textAlign: "center",
          mt: 5,
        }}
      >
        {message}
      </SmallText>
      {btnAction && btnLabel && (
        <Box
          sx={{
            mt: 7,
            width: "50%",
          }}
        >
          <SolidButton
            sx={{
              width: "100%",
            }}
            variant="contained"
            color="primary"
            onClick={btnAction}
          >
            {btnLabel}
          </SolidButton>
        </Box>
      )}
    </Box>
  );
};

export default AuthActionsSuccessState;
