import React from "react";

import { Box, Button, Drawer, Typography, useTheme } from "@mui/material";
import HalfCloudLeft from "../../assets/half_cloud_left.svg";
import HalfCloudLeftWider from "../../assets/half_cloud_left_wider.svg";
import HalfCloudLeftMobile from "../../assets/half_cloud_left_mobile.svg";
import HalfCloudRight from "../../assets/half_cloud_right.svg";
import HalfCloudRightWider from "../../assets/half_cloud_right_wider.svg";
import HalfCloudRightMobile from "../../assets/half_cloud_right_mobile.svg";
import { AppBar, APP_BAR_HEIGHT } from "../../ui/AppBar";
import styled from "@emotion/styled/macro";
import MenuSection from "./MenuSection";
import HeroSection from "./HeroSection";
import { debugColor } from "../../debugTools/debugColors";
import FeatureSection from "./FeatureSection";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import DrawerBody from "./DrawerBody";
import WhyUsSection from "./WhyUsSection";
import ForWhoSection from "./ForWhoSection";
import PricingSection from "./PricingSection";
import CloudContainer from "./CloudContainer";
import Footer from "./Footer";
import { ROUTE_LANDING_PAGE } from "../../routers/routes";
import theme from "../../theme";

const drawerWidth = 246;

export const MenuText = styled(Typography)`
  color: #3a3a3a;
  /* font-family: var(--Title-Large-Font, Roboto); */
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  /* line-height: var(--Title-Large-Line-Height, 28px); */
  /* letter-spacing: var(--Title-Large-Tracking, 0px); */
`;

export const TagLineText = styled(Typography)`
  color: #3a3a3a;
  font-feature-settings: "liga" off, "clig" off;

  /* typography/h4 */
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: 400;
  line-height: 123.5%; /* 41.99px */
  letter-spacing: 0.25px;
`;

export const HeaderText = styled(Typography)`
  color: #3a3a3a;
  /* font-family: var(--Title-Large-Font, Roboto); */
  font-style: normal;
  font-weight: 800;
`;

export const GradeintText = styled(HeaderText)`
  background: linear-gradient(90deg, #2196f3 0%, #0ecfcf 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const SimpleButton = styled(Button)`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: var(--1, 8px);
  box-shadow: 2px 2px 10px 0px rgba(230, 230, 230, 0.2);

  border-radius: 4px;
  border: 2px solid #2196f3;
  background: #ffffff;

  /* font-family: Roboto; */
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  color: #2196f3;
`;

export const GradientButton = styled(SimpleButton)`
  background: linear-gradient(90deg, #2196f3 0%, #10ccd2 100%);
  border: 0px;
  color: #ffffff;
`;

export const DIVIDER_STYLE = {
  height: "2px",
  background: "linear-gradient(90deg, #9ED2FA 0%, #10CCD2 100%)",
  width: "80%",
};

const DOM_ID_FEATURE = "#features";
const DOM_ID_FOR_WHO = "#for-who";
const DOM_ID_PRICING = "#pricing";

export const EMAIL_CONTACT = "info@whileone.in";
export const EMAIL_BOOK_DEMO = "info@whileone.in";

/**
 * This page is refraining from using the custom components that we have used
 * elsewhere in the project. This design(and probably the philosophy) was different
 * than the rest of the product, hence so.
 * @returns
 */
export default function LandingPage() {
  const breakpoint = useBreakpoint();

  const scrollToId = (idWithHash) => {
    // TODO: change this as its an
    // anti pattern in react.
    const section = document.getElementById(idWithHash);
    section.scrollIntoView({ behavior: "smooth" });
  };

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#FFFFFF",
      }}
    >
      <AppBar toggleDrawer={toggleDrawer(true)} />
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <DrawerBody
          open={open}
          setOpen={setOpen}
          onFeaturesClick={() => {
            scrollToId(DOM_ID_FEATURE);
          }}
          onForWhoClick={() => {
            scrollToId(DOM_ID_FOR_WHO);
          }}
          onPricingClick={() => {
            scrollToId(DOM_ID_PRICING);
          }}
        />
      </Drawer>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            mobile: "row",
            tablet: "column",
            laptop: "row",
            desktop: "row",
          },
          justifyContent: "space-between",
          mt: `${APP_BAR_HEIGHT}px`,
          height: {
            mobile: "90vh",
            tablet: "94vh",
            laptop: "94vh",
            desktop: "94vh",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            alignSelf: {
              mobile: "center",
              tablet: "end",
              laptop: "end",
              desktop: "end",
            },
            height: {
              mobile: "158px",
              tablet: "230px",
              laptop: "400px",
              desktop: "551px",
            },
            mt: {
              /* mobile: "8px",
               tablet: "230px",
              laptop: "400px",
              desktop: "551px", */
            },
          }}
          component="img"
          src={
            breakpoint === "desktop" || breakpoint === "laptop"
              ? HalfCloudRight
              : breakpoint === "mobile"
              ? HalfCloudRightMobile
              : HalfCloudLeftWider
          }
        />
        <Box
          sx={{
            background: debugColor("red"),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuSection
            onFeaturesClick={() => {
              scrollToId(DOM_ID_FEATURE);
            }}
            onForWhoClick={() => {
              scrollToId(DOM_ID_FOR_WHO);
            }}
            onPricingClick={() => {
              scrollToId(DOM_ID_PRICING);
            }}
          />
          <HeroSection />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignSelf: {
              mobile: "start",
              tablet: "start",
              laptop: "start",
              desktop: "start",
            },
            justifyContent: "center",
            /* position: "relative",
            left: "-14vw", */
            /* backgroundImage: `url(${HalfCloudLeft})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            width: "100vw", */
            mt: {
              mobile: "16px",
              tablet: "0px",
              laptop: "0px",
              desktop: "0px",
            },
            height: {
              mobile: "103px",
              tablet: "266px",
              laptop: "395px",
              desktop: "545px",
            },
          }}
          component="img"
          src={
            breakpoint === "desktop" || breakpoint === "laptop"
              ? HalfCloudLeft
              : breakpoint === "mobile"
              ? HalfCloudLeftMobile
              : HalfCloudRightWider
          }
        />
      </Box>
      <Box id={DOM_ID_FEATURE}>
        <FeatureSection />
      </Box>
      {/* <CloudContainer
        id={DOM_ID_FOR_WHO}
        sx={{
          height: {
            mobile: "115vh",
            tablet: "80vh",
            laptop: "100vh",
            desktop: "110vh",
          },
        }}
      >
        <WhyUsSection />
      </CloudContainer> */}
      <WhyUsSection />
      <Box id={DOM_ID_FOR_WHO}>
        <ForWhoSection />
      </Box>

      <Box id={DOM_ID_PRICING}>
        <PricingSection />
      </Box>
      {/* <CloudContainer
        id={DOM_ID_PRICING}
        sx={{
          mb: {
            mobile: "0px",
            tablet: "140px",
            laptop: "152px",
            desktop: "152px",
          },
        }}
      >
        <PricingSection />
      </CloudContainer> */}
      <Footer />
    </Box>
  );
}
