import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar as BaseAppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  MuiAppBar,
  MuiDrawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  Divider,
  CssBaseline,
} from "@mui/material";
import { ROUTE_DASHBOARD, ROUTE_LANDING_PAGE } from "../routers/routes";
import history from "../utils/history";
import CloudPulseLogo from "../assets/cloud_pulse_logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { useBreakpoint } from "../hooks/useBreakpoint";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Drawer, DrawerHeader } from "../components/common/drawer/MiniDrawer";
import { IS_DEBUG } from "../config/config";

export const APP_BAR_HEIGHT = 60;
export const APP_BAR_LOGO_HEIGHT = 48;
const APP_BAR_HEIGHT_LG = 60;
// TODO: make height responsive
const APP_BAR_HEIGHT_RESPONSIVE = {
  mobile: `${APP_BAR_HEIGHT}px`,
  tablet: `${APP_BAR_HEIGHT}px`,
  laptop: `${APP_BAR_HEIGHT}px`,
  desktop: `${APP_BAR_HEIGHT_LG}px`,
};

export const AppBar = ({ toggleDrawer }) => {
  const breakpoint = useBreakpoint();

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      <BaseAppBar
        position="fixed"
        sx={{
          background: "white",
          height: `${APP_BAR_HEIGHT}px`,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{
              visibility: {
                mobile: "visible",
                tablet: "visible",
                laptop: "hidden",
                desktop: "hidden",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              onClick={() => {
                history.push(ROUTE_LANDING_PAGE);
              }}
              sx={{
                cursor: "pointer",
                height: `${APP_BAR_LOGO_HEIGHT}px`,
              }}
              component="img"
              src={CloudPulseLogo}
            />
            {IS_DEBUG && (
              <Box>
                <Typography color="black">{breakpoint}</Typography>
              </Box>
            )}
          </Box>
        </Toolbar>
      </BaseAppBar>
    </Box>
  );
};
