import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { SolidButton } from "../../ui/SolidButton"; // Ensure SolidButton is correctly imported
import { Box, Button, Typography } from "@mui/material";
import { H4 } from "../../ui/H4";

/**
 * This is not going to be a generic class. Thats why you will find some of the strings being hardcoded
 * in here. If you want a generic class, look for ConfirmationDialog
 * @param {*} param0
 * @returns
 */
const ConfirmDialog = ({
  open,
  subtitle,
  handleClose,
  handleFormSubmit,
  selectedCloudProvider,
  editMode,
  renderFields,
  error,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} disableEnforceFocus={false}>
      <DialogTitle disableTypography>
        <Typography variant="h4">
          {editMode ? "Edit Token" : "Add Token"}
        </Typography>
        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
      </DialogTitle>
      <DialogContent dividers>
        {renderFields}
        <Box
          sx={{
            color: "red",
          }}
        >
          <Typography
            sx={{
              my: 1,
            }}
          >
            {error}
          </Typography>
        </Box>
      </DialogContent>
      {selectedCloudProvider !== "oci" && (
        <DialogActions>
          <>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <SolidButton onClick={handleFormSubmit} color="primary">
              Ok
            </SolidButton>
          </>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ConfirmDialog;
