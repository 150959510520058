import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticated } from '../utils/jwtUtil';
import { ROUTE_ADD_NEW_CLOUD_PROVIDER } from './routes';

const RestrictRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Redirect
                    to={{
                        /* todo: here we are supposed to check if the user has configured cloud. If yes, take to dashboard, if no take to tokensetup
                        */
                        pathname: ROUTE_ADD_NEW_CLOUD_PROVIDER,
                        state: { from: props.location },
                    }}
                />
            ) : (
                    <Component {...props} />
            )
        }
    />
);

export default RestrictRoute;