import React from "react";
import { Typography, Box } from "@mui/material";
import { useTheme } from "@material-ui/core/styles";

import {
  ADDITIONAL_RECOMMENDATION_TYPE,
  getItemStyle,
} from "../recommendationDetails/RecommendationDetails";
import { formatCurrency } from "../../utils/commonUtil";
import { Card } from "../../ui/Card";
import {
  RECOMMENDATION_TYPE_CHANGING_ARCH,
  RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE,
  RECOMMENDATION_TYPE_OPTIMAL,
  RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS,
} from "../../utils/constants";
import { Dot } from "../../ui/Dot";

const getLabelForAction = (action) => {
  if (action === null) {
    return "Optimal";
  } else if (action === RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE) {
    return "Downsize";
  } else if (action === RECOMMENDATION_TYPE_CHANGING_ARCH) {
    return "Change Architecture";
  } else if (action === RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS) {
    return "Terminate";
  } else if (action === RECOMMENDATION_TYPE_OPTIMAL) {
    return "Optimal";
  } else {
    console.error("Some unknonw action received", action);
    return "Invalid";
  }
};

const getColorForAction = (action) => {
  if (action === null) {
    return "black";
  } else if (action === RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE) {
    return "#FFCC00";
  } else if (action === RECOMMENDATION_TYPE_CHANGING_ARCH) {
    return "#9747FF";
  } else if (action === RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS) {
    return "#EF1F25";
  } else if (action === RECOMMENDATION_TYPE_OPTIMAL) {
    return "#358A01";
  } else {
    console.error("Some unknonw action received", action);
    return "black";
  }
};

const DotLabel = ({ label }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Dot
        style={{
          background: getColorForAction(label),
        }}
      />
      <Typography>{getLabelForAction(label)}</Typography>
    </Box>
  );
};

const AlternateRecommendations = ({ vm }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        gap: 1,
      }}
    >
      {vm.alternate_recommendations?.map((action) => {
        return (
          <>
            <DotLabel label={action} />
          </>
        );
      })}
    </Box>
  );
};

const RecommendationCard = ({ vm, onClick, selectedVm, recomType }) => {
  const theme = useTheme();
  console.log("recomType: ", recomType);

  const getBorder = (action) => {
    // TODO: fix this after the backend fixes it.
    if (action === RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE) {
      return "1px solid orange";
    } else if (action === RECOMMENDATION_TYPE_CHANGING_ARCH) {
      return "1px solid green";
    } else if (action === RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS) {
      return "1px solid yellow";
    } else {
      return "1px solid yellow";
    }
  };

  return (
    <Card
      sx={{
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        padding: "16px",
        borderRadius: "8px",
        ...getItemStyle(theme, vm?.instance_id === selectedVm?.instance_id),
      }}
    >
      <Box
        key={vm.instance_id}
        sx={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
        }}
        onClick={() => onClick(vm)}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexDirection: "column",
          }}
        >
          <Box>
            <Typography variant="body" fontWeight="600">
              {vm.instance_name}
            </Typography>
            <br />
            <br />
            <Typography variant="body2" fontWeight="600">
              Machine Type: {vm.machine_type}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              Memory: {vm.memory}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              vCPUs: {vm.vcpu}
            </Typography>
            {vm.ocpu && (
              <Typography variant="body2" fontWeight="600">
                oCPUs: {vm.ocpu}
              </Typography>
            )}
            <Typography variant="body2" fontWeight="600">
              Architecture: {vm.architecture}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body">Current Price</Typography>
            <Typography variant="body2">
              {formatCurrency(null, vm.price_per_month)}/Month
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {recomType === ADDITIONAL_RECOMMENDATION_TYPE ? (
            <AlternateRecommendations vm={vm} />
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: getBorder(vm.recommended_action),
                  borderRadius: "8px",
                  p: 0.8,
                }}
              >
                <Typography variant="text" fontWeight="600">
                  {getLabelForAction(vm.recommended_action)}
                </Typography>
                <Typography color="green" variant="body" fontWeight="600">
                  Save - {vm.percentage_saved}%
                </Typography>
              </Box>
              <AlternateRecommendations vm={vm} />
            </>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default RecommendationCard;
