import { Box, Card, Divider, Grid } from "@mui/material";
import React from "react";
import styled from "@emotion/styled/macro";
import TestimonialTekdi from "../../assets/png/testimonial-tekdi.png";
import {
  CARD_DESC_STYLE,
  CARD_HEADING_STYLE,
  CardDescription,
  CardHeading,
} from "./FeatureSection";
import { GradeintText } from "./LandingPage";

const ForWhoCardStyled = styled(Card)`
  background: #f9fdff;
  border: 2px solid #2196f3;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
  /* border-image: linear-gradient(to right, #2196f3 0%, #0ecfcf 100%) 1; */
  /* border-width: 4px;
  border-style: solid; */
`;

const CARD_TYPE_TESTIMONIAL = "testimonial";

const Quote = ({ start }) => {
  const quoteEnd = "\u201D";
  const quoteStart = "\u201C";

  return (
    <span
      style={{
        fontSize: "24px",
      }}
    >
      {start ? `${quoteStart} ` : ` ${quoteEnd}`}
    </span>
  );
};

const SECTION_DATA = [
  {
    heading: "Single Screen View",
    description:
      "Optimize and manage your multicloud expenses effortlessly with our comprehensive, single-screen view. Gain real-time insights and streamline your cloud cost management to maximize savings and efficiency",
  },
  {
    heading: "Solid foundations",
    description:
      "Built on 15 years of benchmarking expertise from our founders. Their knowledge forms the cornerstone of our recommendations, driving precision and reliability in every insight.",
  },
  {
    type: CARD_TYPE_TESTIMONIAL,
    heading: "Amol Chitale, DevOps Head @tekditechnologies",
    description: `Bespoke solutions and optimisation suggestions provided by CloudNudge team help us to optimise our cloud costs by ~25%. It does so without any adverse impact to the end customers. CloudNudge has helped in auditing and monitoring cloud services which has resulted in significant savings of various cloud resources. Infrastructure optimisation recommendations are based on exhaustive Policy and Service lists which are continuously evolving giving value proposition to clients.`,
    image: TestimonialTekdi,
  },
];

function WhyUsCard({ feature, sx }) {
  return (
    <ForWhoCardStyled
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        borderRadius: {
          mobile: "4px",
          tablet: "12px",
          laptop: "12px",
          desktop: "12px",
        },
        height: {
          mobile: "92%",
          tablet: "78%",
          laptop: "78%",
          desktop: "78%",
        },
        gap: {
          mobile: "6px",
          tablet: "16px",
          laptop: "20px",
          desktop: "16px",
        },
        py: {
          mobile: "14px",
          tablet: "28px",
          laptop: "35px",
          desktop: "31px",
        },
        pl: {
          mobile: "22px",
          tablet: "45px",
          laptop: "45px",
          desktop: "45px",
        },
        pr: {
          mobile: "20px",
          tablet: "25px",
          laptop: "36px",
          desktop: "45px",
        },
        ...sx,
      }}
    >
      <CardHeading
        sx={{
          ...CARD_HEADING_STYLE,
          textAlign: "start",
        }}
      >
        {feature.heading}
      </CardHeading>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: {
            mobile: "12px",
            tablet: "18px",
            laptop: "36px",
            desktop: "40px",
          },
        }}
      >
        {feature?.type === CARD_TYPE_TESTIMONIAL && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              aspectRatio: 1,
              height: {
                mobile: "42px",
                tablet: "70px",
                laptop: "101px",
                desktop: "98px",
              },
            }}
            component="img"
            src={feature.image}
          />
        )}
        <CardDescription sx={CARD_DESC_STYLE}>
          {feature?.type === CARD_TYPE_TESTIMONIAL && <Quote start={true} />}
          {feature.description}
          {feature?.type === CARD_TYPE_TESTIMONIAL && <Quote start={false} />}
        </CardDescription>
      </Box>
    </ForWhoCardStyled>
  );
}

export default function WhyUsSection() {
  return (
    <Box
      sx={{
        // position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        mt: {
          mobile: "52px",
          tablet: "72px",
          laptop: "72px",
          desktop: "92px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GradeintText variant="h6">Why Us?</GradeintText>
      </Box>
      <Grid
        container
        justifyContent="center"
        gap={{
          mobile: 2,
          tablet: 0,
          laptop: 0,
          desktop: 0,
        }}
        rowSpacing={{
          mobile: "24px",
          tablet: "36px",
          laptop: "34px",
          desktop: "72px",
        }}
        columnSpacing={{
          mobile: 2,
          tablet: "32px",
          laptop: "20px",
          desktop: "72px",
        }}
        sx={{
          pt: {
            mobile: "36px",
            tablet: "32px",
            laptop: "72px",
            desktop: "120px",
          },
          mb: {
            mobile: "0px",
            tablet: "0px",
            laptop: "104px",
            desktop: "0px",
          },
          px: {
            mobile: "25px",
            tablet: "45px",
            laptop: "60px",
            desktop: "88px",
          },
        }}
      >
        {SECTION_DATA.map((feature, index) => {
          return (
            <Grid
              item
              mobile={index === 0 ? 12 : 12}
              tablet={index === 0 ? 12 : 12}
              laptop={index === 0 || index === 2 ? 8 : 4}
              desktop={index === 0 || index === 2 ? 8 : 4}
            >
              <WhyUsCard feature={feature} />
            </Grid>
          );
        })}
      </Grid>
      <Divider
        sx={{
          height: "2px",
          background: "linear-gradient(90deg, #9ED2FA 0%, #10CCD2 100%)",
          width: "80%",
          visibility: {
            mobile: "hidden",
            tablet: "hidden",
            laptop: "visible",
            desktop: "hidden",
          },
        }}
      />
    </Box>
  );
}
