import {
  CACHED_DATA_CACHE_TIME,
  CACHED_DATA_STALE_TIME,
} from "../config/config";
import { QUERY_KEY_ACC_TOKENS } from "../utils/constants";
import { fetch } from "../utils/httpUtil";
import { useQuery } from "@tanstack/react-query";
import { parseNetworkErrorV2 } from "../utils/commonUtil";

const getCloudProviderAccountTokens = async (cloudProvider) => {
  try {
    const resp = await fetch(`/recommendations/list-keys/${cloudProvider}/`);
    return resp;
  } catch (error) {
    const parsedError = parseNetworkErrorV2(error.response);
    throw parsedError;
  }
};

/**
 * Get all the tokens/accesskey added for this user.
 * @param {*} cloudProvider
 * @returns
 */
export function useCloudProviderAccountTokens(cloudProvider) {
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: [QUERY_KEY_ACC_TOKENS, cloudProvider],
    queryFn: async () => getCloudProviderAccountTokens(cloudProvider),
    staleTime: CACHED_DATA_STALE_TIME,
    cacheTime: CACHED_DATA_CACHE_TIME,
  });

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
  };
}
