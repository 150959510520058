import axios from "axios";
import { push } from "connected-react-router";
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  signupFailure,
  signupSuccess,
  loginInProgress,
  insertTokenProgress,
  signupInProgress,
} from "../actions/authAction";
import { API_URL } from "../config/config";
import { setLocalStorage, clearLocalStorage } from "../utils/storageUtil";
import {
  ROUTE_DASHBOARD,
  ROUTE_LOGIN,
  ROUTE_VERIFY_EMAIL,
} from "../routers/routes";
import { parseNetworkError } from "../utils/commonUtil";
import { STORAGE_AUTH_TOKEN, STORAGE_USER_INFO } from "../utils/constants";

export const login = ({ email, password }) => {
  return (dispatch) => {
    dispatch(loginInProgress());
    axios
      .post(API_URL + "auth/login/", { email, password })
      .then((response) => {
        console.log("response: ", response);
        dispatch(loginSuccess(response.data.access));
        setLocalStorage(STORAGE_AUTH_TOKEN, response.data.access);
        setLocalStorage(STORAGE_USER_INFO, response.data);
        dispatch(push(ROUTE_DASHBOARD));
      })
      .catch((error) => {
        console.log("error: ", error);
        dispatch(
          loginFailure({
            message: `${parseNetworkError({
              error: error?.response?.data?.error ?? error.message.toString(),
            })}`,
          })
        );
      });
  };
};

export const signup = ({ first_name, last_name, email, password }) => {
  return (dispatch) => {
    dispatch(signupInProgress());
    axios
      .post(API_URL + "auth/register/", {
        email,
        first_name,
        last_name,
        password,
        role_id: 1,
      })
      .then((response) => {
        dispatch(push(ROUTE_VERIFY_EMAIL));
      })
      .catch((error) => {
        console.log("err.response: ", error?.response?.data?.error);
        console.log("error.message: ", error.message.toString());
        dispatch(
          signupFailure({
            message: `${parseNetworkError({
              error: error?.response?.data?.error ?? error.message.toString(),
            })}`,
          })
        );
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    clearLocalStorage(STORAGE_AUTH_TOKEN);
    clearLocalStorage(STORAGE_USER_INFO);
    dispatch(logoutSuccess());
    dispatch(push(ROUTE_LOGIN));
    return false;
  };
};
