import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@mui/material";

// Import custom components
import { SolidButton } from "../../ui/SolidButton";
import { SmallText } from "../../ui/SmallText";
import { isValidEmail } from "../../utils/commonUtil";
import useAuth from "../../hooks/useAuth";
import AuthActionsSuccessState from "./AuthActionsSuccessState";
import Alert from "@mui/material/Alert";
import { H3 } from "../../ui/H3";
import { LeftPane } from "./LeftPane";
import { RightPane } from "./RightPane";
import { BottomContainer, FullScreenContainer } from "./LoginForm";
import { BrandLogo } from "./BrandLogo";
import { InputTextField } from "../../ui/InputTextField";

const UiFields = ({ onResetPasswdLinkSent }) => {
  const { resetPassword, isLoading, data, error } = useAuth();
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState(error);

  const handleChange = (e) => {
    setValidationError(null);
    const newValue = e.target.value;
    if (isValidEmail(newValue)) {
      setEmail(newValue);
    } else {
      setEmail(null);
    }
  };

  useEffect(() => {
    if (data?.data?.message) {
      onResetPasswdLinkSent(data?.data?.message);
    }

    if (error) {
      setValidationError(error);
    }
  }, [data, error]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <H3
        sx={{
          textAlign: "center",
          mt: 7,
        }}
      >
        Forgot Password?
      </H3>
      <SmallText
        sx={{
          mt: 4,
        }}
      >
        Please enter your email to reset your password
      </SmallText>
      <Box
        mt={3}
        mb={1}
        sx={{
          width: "100%",
        }}
      >
        <InputTextField
          placeholder="joe@example.com"
          label="Email address"
          onChange={handleChange}
          fullWidth
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              resetPassword(ev.target.value);
              ev.preventDefault();
            }
          }}
        />
      </Box>
      <BottomContainer>
        {validationError && (
          <Alert severity="warning" sx={{ my: 1 }}>
            {validationError
              ? validationError
              : `Failed to send password reset link. Please try again`}
          </Alert>
        )}
        {isLoading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <SolidButton
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            disabled={!email}
            onClick={() => {
              resetPassword(email);
            }}
          >
            Send Reset Password Link
          </SolidButton>
        )}
      </BottomContainer>
    </Box>
  );
};

const ForgotPassword = (props) => {
  const theme = useTheme();
  const [resetPasswdLinkSent, setResetPasswdLinkSent] = useState(false);

  const onResetPasswdLinkSent = (message) => {
    setResetPasswdLinkSent(true);
  };

  return (
    <FullScreenContainer>
      <LeftPane />
      <RightPane>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <BrandLogo />
          {resetPasswdLinkSent ? (
            <AuthActionsSuccessState
              title="Password Reset Link Sent!"
              message="We have sent a password reset link to your email. Please proceed using
                that link."
            />
          ) : (
            <UiFields onResetPasswdLinkSent={onResetPasswdLinkSent} />
          )}
        </Box>
      </RightPane>
    </FullScreenContainer>
  );
};

export default ForgotPassword;
