// import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  List,
  Divider,
  CssBaseline,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SettingsIcon from "@mui/icons-material/Settings";
import MailIcon from "@mui/icons-material/Mail";
import LogoutIcon from "@mui/icons-material/Logout";

import history from "../../../utils/history";
import {
  ROUTE_ADD_NEW_CLOUD_PROVIDER,
  ROUTE_DASHBOARD,
  ROUTE_RECOMMENDATION_DETAILS,
} from "../../../routers/routes";
import { useDispatch } from "react-redux";
import { logout } from "../../../services/authService";
import ConfirmationDialog from "../../../ui/ConfirmationDialog";
import { useState } from "react";
import { USER_FACING_PRODUCT_NAME } from "../../../utils/constants";
import CloudPulseLogo from "../../../assets/cloud_pulse_logo.svg";
import { useQueryClient } from "@tanstack/react-query";

const DRAWER_LABEL_DASHBOARD = "Dashboard";
const DRAWER_LABEL_RECOMMENDATIONS = "Recommendations";
const DRAWER_LABEL_ADD_TOKEN = "Add Token";

const ICON_STYLE = {
  textAlign: "center",
  verticalAlign: "center",
  fontSize: "24px",
  color: "#3A3A3A",
  width: "24px",
  height: "24px",
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* TODO: Refactor this with our own AppBar class */}
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: "white",
        }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            variant="h4"
            noWrap
            component="div"
            onClick={() => {
              history.push(ROUTE_DASHBOARD);
            }}
            sx={{
              cursor: "pointer",
            }}
          >
            {USER_FACING_PRODUCT_NAME}
          </Typography> */}
          <Box
            onClick={() => {
              history.push(ROUTE_DASHBOARD);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "auto",
              height: "48px",
              cursor: "pointer",
            }}
            component="img"
            src={CloudPulseLogo}
          />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {[
            DRAWER_LABEL_DASHBOARD,
            DRAWER_LABEL_RECOMMENDATIONS,
            DRAWER_LABEL_ADD_TOKEN,
          ].map((text, index) => (
            <Tooltip title={text} disableHoverListener={open ? true : false}>
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    if (text === DRAWER_LABEL_DASHBOARD) {
                      history.push(ROUTE_DASHBOARD);
                    } else if (text === DRAWER_LABEL_RECOMMENDATIONS) {
                      history.push(ROUTE_RECOMMENDATION_DETAILS);
                    } else if (text === DRAWER_LABEL_ADD_TOKEN) {
                      history.push(ROUTE_ADD_NEW_CLOUD_PROVIDER);
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {text === DRAWER_LABEL_DASHBOARD ? (
                      <>
                        <i
                          class="fi fi-rr-dashboard-panel"
                          style={ICON_STYLE}
                        />
                      </>
                    ) : text === DRAWER_LABEL_RECOMMENDATIONS ? (
                      <>
                        <i class="fi fi-rr-rectangle-list" style={ICON_STYLE} />
                      </>
                    ) : (
                      <>
                        <i class="fi fi-rr-square-plus" style={ICON_STYLE} />
                      </>
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>
        <Divider />
        <ConfirmationDialog
          open={dialogOpen}
          title={"Are you sure you want to log out?"}
          onClose={() => {
            setDialogOpen(false);
          }}
          onNegativeBtnClick={() => {
            setDialogOpen(false);
          }}
          onPositiveBtnClick={() => {
            queryClient.removeQueries();
            dispatch(logout());
          }}
        />
        <List style={{ position: "absolute", bottom: 0 }}>
          {["Logout"].map((text, index) => (
            <ListItem
              key={text}
              disablePadding
              sx={{ display: open ? "block" : "inline" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  // px: 2.5,
                }}
                onClick={() => {
                  if (text === "Logout") {
                    setDialogOpen(true);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <>
                    <i class="fi fi-rr-sign-out-alt" style={ICON_STYLE} />
                  </>
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
