import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import loadable from "@loadable/component";

// Import custom components
import PrivateRoute from "./PrivateRoute";
import RestrictRoute from "./RestrictRoute";
import PublicRoute from "./PublicRoute";

import MainLayout from "../components/common/layout/MainLayout";
import NotFound from "../components/error/NotFound";
import AddCloudProvider from "../components/addCloudProvider/AddCloudProvider";
import EmailVerificationResult from "../components/auth/EmailVerificationResult";
import ForgotPassword from "../components/auth/ForgotPassword";
import SetPassword from "../components/auth/SetPassword";
import RecommendationDetails from "../components/recommendationDetails/RecommendationDetails";
import LandingPage from "../pages/landingPage/LandingPage";

const AsyncLoginForm = loadable(() =>
  import("../containers/auth/LoginContainer")
);
const AsyncSignUpForm = loadable(() =>
  import("../containers/auth/SignUpContainer")
);
const AsyncDashboard = loadable(() =>
  import("../containers/dashboard/DashboardContainer")
);

export const ROUTE_LANDING_PAGE = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_SIGNUP = "/signup";
export const ROUTE_VERIFY_EMAIL = "/verify-email";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_RECOMMENDATION_DETAILS = "/recommendation-details";
export const ROUTE_ADD_NEW_CLOUD_PROVIDER = "/add-cloud-provider";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";

const Router = () => (
  <Fragment>
    <Switch>
      <RestrictRoute exact path={ROUTE_LOGIN} component={AsyncLoginForm} />
      <RestrictRoute exact path={ROUTE_SIGNUP} component={AsyncSignUpForm} />
      <Route
        exact
        path={ROUTE_VERIFY_EMAIL}
        component={EmailVerificationResult}
      />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route
        exact
        path="/auth/reset-password-confirm/*"
        component={SetPassword}
      />
      <PublicRoute
        exact
        path={ROUTE_LANDING_PAGE}
        layout={MainLayout}
        component={LandingPage}
      />
      <PrivateRoute
        exact
        path={ROUTE_ADD_NEW_CLOUD_PROVIDER}
        layout={MainLayout}
        component={AddCloudProvider}
      />
      <PrivateRoute
        exact
        path={ROUTE_DASHBOARD}
        layout={MainLayout}
        component={AsyncDashboard}
      />
      <PrivateRoute
        exact
        path={ROUTE_RECOMMENDATION_DETAILS}
        layout={MainLayout}
        component={RecommendationDetails}
      />
      <Route component={NotFound} />
    </Switch>
  </Fragment>
);

export default Router;
