import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import history from "../../utils/history";
import { ROUTE_DASHBOARD } from "../../routers/routes";
import { EMAIL_CONTACT, GradientButton } from "./LandingPage";
import { ButtonStyle } from "./HeroSection";

export default function DrawerBody({
  open,
  setOpen,
  onFeaturesClick,
  onForWhoClick,
  onPricingClick,
}) {
  return (
    <>
      <Toolbar />
      <List>
        {["Features", "For Who?", "Pricing"].map((text, index) => (
          <ListItem key={text} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                setOpen(false);
                if (text === "Features") {
                  onFeaturesClick();
                } else if (text === "For Who?") {
                  onForWhoClick();
                } else if (text === "Pricing") {
                  onPricingClick();
                }
              }}
            >
              <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider
        sx={{
          height: "2px",
          background: "linear-gradient(90deg, #9ED2FA 0%, #10CCD2 100%)",
          width: "80%",
          alignSelf: "center",
        }}
      />
      <Box
        sx={{
          px: 4,
          mt: 3,
        }}
      >
        <GradientButton
          sx={ButtonStyle}
          onClick={() => {
            window.open(`mailto:${EMAIL_CONTACT}`);
          }}
        >
          Talk to us
        </GradientButton>
      </Box>
    </>
  );
}
