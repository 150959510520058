import { Box, Divider, Grid } from "@mui/material";
import React from "react";
import { GradeintText, DIVIDER_STYLE } from "./LandingPage";
import Infographic from "../../assets/gif/infographic.gif";

export default function ForWhoSection() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        mt: {
          mobile: "52px",
          tablet: "100px",
          laptop: "72px",
          desktop: "102px",
        },
      }}
    >
      <Grid
        container
        rowSpacing={{
          mobile: "52px",
          /* tablet: "36px",
          laptop: "34px",
          desktop: "72px", */
        }}
        /* columnSpacing={{
          mobile: 2,
          tablet: "80px",
          laptop: "20px",
          desktop: "72px",
        }} */
        sx={{
          mb: {
            tablet: "80px",
            laptop: "107px",
            desktop: "104px",
          },
          px: {
            mobile: "5%",
            tablet: "10%",
            laptop: "60px",
            desktop: "167px",
          },
        }}
      >
        <Grid item mobile={12} tablet={12} laptop={6} desktop={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: {
                mobile: "auto",
                tablet: "auto",
                laptop: "100%",
                desktop: "100%",
              },
              width: {
                mobile: "100%",
                tablet: "100%",
                laptop: "auto",
                desktop: "auto",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: `url(${Infographic})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                aspectRatio: 1,
                width: {
                  mobile: "80%",
                  tablet: "370px",
                  laptop: "346px",
                  desktop: "500px",
                },
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          tablet={12}
          sx={{
            alignSelf: "center",
            display: {
              mobile: "none",
              tablet: "block",
              laptop: "none",
              desktop: "none",
            },
          }}
        >
          <Divider
            sx={{
              ...DIVIDER_STYLE,
              alignSelf: "center",
              width: "100%",
              display: {
                mobile: "none",
                tablet: "block",
                laptop: "none",
                desktop: "none",
              },
            }}
          />
        </Grid>
        <Grid item mobile={12} tablet={12} laptop={6} desktop={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: {
                mobile: "29px",
                tablet: "36px",
                laptop: "72px",
                desktop: "84px",
              },
            }}
          >
            <GradeintText variant="h6">For Who?</GradeintText>
            <Box
              sx={{
                aspectRatio: 1.5,
                width: {
                  mobile: "100%",
                  tablet: "100%",
                  laptop: "100%",
                  desktop: "750px",
                },
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/Vu938HgKKr8?si=LxJuoRNf_JceKBl6"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                style={{
                  borderRadius: "12px",
                  border: "2px solid #2196F3",
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider
        sx={{
          ...DIVIDER_STYLE,
          display: {
            mobile: "none",
            tablet: "none",
            laptop: "block",
            desktop: "none",
          },
        }}
      />
    </Box>
  );
}
