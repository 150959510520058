import styled from "@emotion/styled/macro";
import { Box } from "@mui/material";

const CustomDot = styled(Box)`
  border-radius: 40px;
  background: red;
  width: 6px;
  height: 6px;
`;

export const Dot = (props) => {
  return <CustomDot {...props} />;
};
