import React, { useState } from "react";
import { CardContent, Typography, Box, Paper } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import SwitchSeriesType from "../SwitchSeriesType"; // Adjust the path as per your file structure
import ListIcon from "@mui/icons-material/List";
import { H4 } from "../../ui/H4";
import { formatCurrency } from "../../utils/commonUtil";
import { Chip } from "../../ui/Chip";
import { Card } from "../../ui/Card";
import { MonitoringGraph } from "./MonitoringGraph";
import { MonitoringMemoryGraph } from "./MonitoringMemoryGraph";
import { CLOUD_PROVIDER_AWS, CLOUD_PROVIDER_OCI } from "../../utils/constants";

const columns = [
  {
    field: "machine_type",
    headerName: "Machine Type",
    width: 140,
    filterable: true,
  },
  { field: "v_cpus", headerName: "vCPUs", width: 100, filterable: true },
  {
    field: "memoryGiB",
    headerName: "Memory (GiB)",
    width: 120,
    filterable: true,
  },
  {
    field: "new_machine_monthly_price",
    headerName: "Price per Month",
    width: 130,
    filterable: true,
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          {formatCurrency(null, value.row.new_machine_monthly_price)}
        </Box>
      );
    },
  },
  {
    field: "usage_cpu",
    headerName: "CPU Usage",
    width: 120,
    filterable: true,
    sortable: false,
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <i class="fi fi-sr-stats" />
        </Box>
      );
    },
  },
  {
    field: "usage_memory",
    headerName: "Memory Usage",
    width: 120,
    filterable: true,
    sortable: false,
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <i class="fi fi-sr-stats" />
        </Box>
      );
    },
  },
  {
    field: "cost_saving",
    headerName: "Saving Opportunity ($)",
    width: 250,
    filterable: true,
    renderCell: (value) => {
      return (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          Save {formatCurrency(null, value.row.cost_saving)}/m
          <Chip
            sx={{
              backgroundColor: "transparent",
              color: "#358A01",
              border: "1px solid grey",
              borderRadius: "8px",
              padding: "0px",
              margin: "0px",
              fontWeight: 600,
              minWidth: "70px",
            }}
            label={`SAVE ${value.row.percentage_saved}%`}
          />
        </Box>
      );
    },
  },
  {
    field: "architecture",
    headerName: "Architecture",
    width: 130,
    filterable: true,
  },
  /*{ field: 'network', headerName: 'Network', width: 150, filterable: true },
  { field: 'savingType', headerName: 'Saving Type', width: 150, filterable: true }, */
];

const EmptyStateView = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 4,
        textAlign: "center",
        width: "100%",
        height: "70vh",
        margin: "auto",
        mt: 4,
      }}
    >
      <Box>
        <ListIcon sx={{ fontSize: 150, color: "grey.500" }} />
      </Box>
      <H4>Woops! There's nothing to show right now.</H4>
      <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
        Please select another VM to see the recommendations for...
      </Typography>
    </Paper>
  );
};

const RecommendationSummaryPage = ({
  cloudProvider,
  vm,
  recommendations,
  accessToken,
}) => {
  console.log("recommendations: ", recommendations);
  const recommendationValues = Object.values(recommendations);
  console.log("vm: ", vm);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [memoryDialogOpen, setMemoryDialogOpen] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);

  let rows = recommendationValues
    ? recommendationValues.map((recommendation, index) => ({
        id: index + 1,
        ...recommendation,
      }))
    : [];

  // console.log("rows: ", rows);

  const getColumns = (cloudProvider) => {
    if (cloudProvider === CLOUD_PROVIDER_AWS) {
      return columns;
    } else if (cloudProvider === CLOUD_PROVIDER_OCI) {
      const tempCols = [
        ...columns.slice(0, 2),
        ...[
          { field: "ocpu", headerName: "oCPUs", width: 80, filterable: true },
        ],
        ...columns.slice(2, columns.length),
      ];
      console.log("tempCols: ", tempCols);
      return tempCols;
    } else {
      return columns;
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "end",
          backgroundColor: "#F5F5F5",
        }}
      >
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Card
        style={{
          border: "1px solid #ccc",
          width: "100%",
        }}
      >
        <CardContent>
          {rows.length === 0 ? (
            <EmptyStateView />
          ) : (
            <>
              <Typography variant="h4">
                {vm?.instance_name
                  ? `Recommended VMs for ${vm.instance_name}`
                  : "Recommended VMs"}
              </Typography>
              <Box style={{ height: "50%" }}>
                <DataGrid
                  rows={rows}
                  columns={getColumns(cloudProvider)}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  initialState={{
                    filter: {
                      filterModel: {
                        items: columns.map((col) => ({
                          columnField: col.field,
                          operatorValue: "contains",
                          value: "",
                        })),
                      },
                    },
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                  onCellClick={(params, event) => {
                    event.defaultMuiPrevented = true;
                    console.log("cell clicked:", vm);
                    setSelectedRecommendation(params.row);
                    if (params.field === "usage_cpu") {
                      setDialogOpen(true);
                    } else if (params.field === "usage_memory") {
                      setMemoryDialogOpen(true);
                    }
                  }}
                />
                {/* <SwitchSeriesType /> */}
              </Box>
            </>
          )}
        </CardContent>
      </Card>
      <MonitoringGraph
        open={dialogOpen}
        vm={vm}
        cloudProvider={cloudProvider}
        accessToken={accessToken}
        recommendation={selectedRecommendation}
        title={"CPU Usage Monitoring"}
        onClose={() => {
          setDialogOpen(false);
        }}
        handleClose={() => {
          setDialogOpen(false);
        }}
      />
      <MonitoringMemoryGraph
        open={memoryDialogOpen}
        vm={vm}
        cloudProvider={cloudProvider}
        accessToken={accessToken}
        recommendation={selectedRecommendation}
        title={"Memory Monitoring"}
        onClose={() => {
          setMemoryDialogOpen(false);
        }}
        handleClose={() => {
          setMemoryDialogOpen(false);
        }}
      />
    </>
  );
};

export default RecommendationSummaryPage;
