import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { CircularProgress, Box } from "@material-ui/core";
import renderText from "../common/form/renderText";
import { SolidButton } from "../../ui/SolidButton";
import styled from "@emotion/styled/macro";
import { H3 } from "../../ui/H3";
import { H4 } from "../../ui/H4";
import { USER_FACING_PRODUCT_NAME } from "../../utils/constants";
import { Link } from "../../ui/Link";
import { LeftPane } from "./LeftPane";
import { RightPane } from "./RightPane";
import { BrandLogo } from "./BrandLogo";
import { ROUTE_FORGOT_PASSWORD, ROUTE_SIGNUP } from "../../routers/routes";

// Styled components using emotion
export const FullScreenContainer = styled(Box)`
  display: flex;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
`;

export const Input = styled(Field)`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
`;

export const BottomContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

const LoginForm = (props) => {
  const { handleSubmit, onSubmit, errorMessage } = props;
  const isLoading = useSelector((state) => state.auth.isLoading);

  return (
    <FullScreenContainer>
      <LeftPane />
      <RightPane>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <BrandLogo />
          <H3
            sx={{
              textAlign: "center",
              mt: 7,
            }}
          >
            Welcome To {USER_FACING_PRODUCT_NAME}
          </H3>
          <H4
            sx={{
              mt: 4,
            }}
          >
            Sign In
          </H4>
          <form method="post" onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Input
                type="text"
                name="email"
                component={renderText}
                label="Email"
              />
              <Input
                type="password"
                name="password"
                component={renderText}
                label="Password"
              />
              <Box
                component="p"
                sx={{
                  margin: "6px 0px 0px 0px",
                  padding: 0,
                  width: "100%",
                  textAlign: "right",
                }}
              >
                <Link to={ROUTE_FORGOT_PASSWORD}>Forgot password?</Link>
              </Box>
            </Box>
            <BottomContainer>
              {errorMessage && (
                <Alert severity="warning" sx={{ my: 1 }}>
                  {errorMessage}
                </Alert>
              )}
              {isLoading ? (
                <CircularProgress />
              ) : (
                <SolidButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ width: "50%" }}
                >
                  Sign In
                </SolidButton>
              )}
              <Box component="p" mt={2}>
                Don't have an account? <Link to={ROUTE_SIGNUP}>Create one</Link>
              </Box>
            </BottomContainer>
          </form>
        </Box>
      </RightPane>
    </FullScreenContainer>
  );
};

const validateLogin = (values) => {
  const errors = {};
  const requiredFields = ["email", "password"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = `The ${field} field is required.`;
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address.";
  }
  return errors;
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default reduxForm({
  form: "LoginForm",
  validate: validateLogin,
})(LoginForm);
