import { Box, Typography, Divider as BaseDivider } from "@mui/material";
import React from "react";
import styled from "@emotion/styled/macro";
import { EMAIL_CONTACT, GradientButton } from "./LandingPage";
import { debugColor } from "../../debugTools/debugColors";
import { ButtonStyle } from "./HeroSection";

const MenuText = styled(Typography)`
  color: #3a3a3a;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  cursor: pointer;
  /* line-height: var(--Title-Large-Line-Height, 28px); */
  /* letter-spacing: var(--Title-Large-Tracking, 0px); */
`;

const Divider = styled(BaseDivider)`
  color: #3a3a3a;
  width: 4px;
  height: 32px;
  border-color: #3a3a3a;
`;

export default function MenuSection({
  onFeaturesClick,
  onForWhoClick,
  onPricingClick,
}) {
  return (
    <Box
      sx={{
        position: "absolute",
        left: {
          mobile: "63px",
          tablet: "63px",
          laptop: "63px",
          desktop: "167px",
        },
        background: debugColor("green"),
        display: {
          mobile: "none",
          tablet: "none",
          laptop: "block",
          desktop: "block",
        },
        marginTop: "43px",
        height: "40px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "left",
      }}
    >
      <Box
        sx={{
          background: debugColor("yellow"),
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          // width: "auto",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            gap: 1.2,
          }}
        >
          <MenuText onClick={onFeaturesClick}>Features</MenuText>
          <Divider orientation="vertical" />
          <MenuText onClick={onForWhoClick}>For Who</MenuText>
          <Divider orientation="vertical" />
          <MenuText onClick={onPricingClick}>Pricing</MenuText>
        </Box>
        <GradientButton
          onClick={() => {
            window.open(`mailto:${EMAIL_CONTACT}`);
          }}
          sx={ButtonStyle}
        >
          Talk to us
        </GradientButton>
      </Box>
    </Box>
  );
}
