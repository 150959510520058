import React, { useState, useEffect } from "react";
import {
  Typography,
  CardContent,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
  CardHeader,
  Paper,
  IconButton,
  InputBase,
  Alert,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@material-ui/core/styles";

import { Card } from "../../ui/Card";
import { Chip } from "../../ui/Chip";
import { TinyText } from "../../ui/TinyText";

import LogoAWS from "../../assets/cloud_provider_aws.svg";
import LogoAzure from "../../assets/cloud_provider_azure.svg";
import LogoGCP from "../../assets/cloud_provider_gcp.svg";
import LogoOci from "../../assets/cloud_provider_oracle.svg";
import CloudProviderCard from "../CloudProviderCard";

import {
  CLOUD_PROVIDER_GCP,
  CLOUD_PROVIDER_AWS,
  CLOUD_PROVIDER_AZURE,
  CLOUD_PROVIDER_OCI,
  STANDARD_RECOMMENDATION_TYPES,
} from "../../utils/constants";
import {
  DEFAULT_CLOUD_PROVIDER,
  DEFAULT_MESSAGE_EMPTY,
} from "../../config/config";
import RecommendationSummaryPage from "./RecommendationSummaryPage";
import {
  formatCurrency,
  getCloudProviderName,
  showFirstAndLastNChars,
} from "../../utils/commonUtil";
import {
  useRecommendationFilters,
  useRecommendationForAVm,
  useRecommendationTypes,
  useVmsAndRecommendations,
  useVmsForACloud,
} from "../../hooks/useRecommendations";

import { useCloudProviderAccountTokens } from "../../hooks/useCloudProviderAccountTokens";
import { SolidButton } from "../../ui/SolidButton";
import { ROUTE_ADD_NEW_CLOUD_PROVIDER } from "../../routers/routes";
import history from "../../utils/history";
import RecommendationCard from "./RecommendationCard";
import { SummaryViewCard } from "./SummaryViewCard";
import { H4 } from "../../ui/H4";

export const ADDITIONAL_RECOMMENDATION_TYPE = "all";
const RECOMMENDATION_TYPES = [
  {
    type: ADDITIONAL_RECOMMENDATION_TYPE,
    label: "All",
  },
  ...STANDARD_RECOMMENDATION_TYPES,
];

const SelectStyle = {
  px: 0,
  mx: 0,
  display: "flex",
  justifyContent: "left",
  alignItems: "left",
};

const getSelectedBorder = (theme) => {
  return `2px solid #2196F3`;
};

export const getItemStyle = (theme, isSelected) => {
  return {
    border: isSelected ? getSelectedBorder(theme) : "none",
  };
};

function SearchInput({ onSearch }) {
  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #ccc",
        boxShadow: "none",
      }}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search VM names & machine types"
        inputProps={{ "aria-label": "search google maps" }}
        onChange={(event) => {
          onSearch(event.target.value);
        }}
        onKeyDown={(event) => {
          console.log("KEY: ", event.key);
          if (event.key === "Enter") {
            event.preventDefault();
            return false;
          }
        }}
      />
    </Paper>
  );
}

const getSanitizedRecommendationType = () => {
  const params = new URLSearchParams(window.location.search);
  const recoTypeParam = params.get("type");
  console.log("recoTypeParam: ", recoTypeParam);

  if (!recoTypeParam || recoTypeParam === "null") {
    console.log("returning !reco: ", null);
    return null;
  }

  if (
    !STANDARD_RECOMMENDATION_TYPES.every(
      (obj) => !obj.hasOwnProperty(recoTypeParam)
    )
  )
    return null;
  else return recoTypeParam;
};

const RecommendationDetails = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [recommendations, setRecommendations] = useState();
  const [selectedVm, setSelectedVm] = useState();
  const [selectedAccessKey, setSelectedAccessKey] = useState("");
  // const [vms, setVms] = useState({}); // ideally the backend should set this as a type []
  const [selectCloudProvider, setSelectCloudProvider] = useState(
    DEFAULT_CLOUD_PROVIDER
  );
  const [filterModel, setFilterModel] = useState({ items: [] });
  const sanitizedRecoType = getSanitizedRecommendationType();
  const theme = useTheme();

  const {
    data: accessKeys,
    isLoading: areAccessKeyLoading,
    isError,
    error,
    refetch,
  } = useCloudProviderAccountTokens(selectCloudProvider);
  console.log("selectCloudProvider: ", selectCloudProvider);
  const [selectedRecomType, setSelectedRecommendType] = useState(
    sanitizedRecoType ?? ADDITIONAL_RECOMMENDATION_TYPE
  );

  let firstAccessKey = null;
  if (accessKeys && accessKeys.data.length > 0) {
    // set first VM selected otherwise the UI for
    // recommendations looks empty.
    firstAccessKey = accessKeys.data[0];
  }

  const {
    data: vms,
    isLoading: areVmsLoading,
    isError: isVmsError,
    error: vmsError,
    refetch: getAllVmsAsync,
  } = useVmsAndRecommendations(
    selectCloudProvider,
    selectedRecomType === ADDITIONAL_RECOMMENDATION_TYPE
      ? null
      : selectedRecomType,
    selectedAccessKey
  );

  // make first available key
  // selected in the UI.
  useEffect(() => {
    if (firstAccessKey && firstAccessKey.id) {
      setSelectedAccessKey(firstAccessKey.id);
      getAllVmsAsync();
    }
  }, [firstAccessKey]);

  console.log("XXX vms.firstload: ", vms);
  console.log("XXX selectedVm: ", selectedVm);

  const selectFirstVm = () => {
    if (vms && vms?.length > 0) {
      const firstVm = vms[0];
      console.log("XXX selecting first: ", vms);
      if (!selectedVm) {
        setSelectedVm(firstVm);
        setRecommendations(firstVm.recommendations);
      }
    }
  };

  // make first vms as selected
  // in the UI
  selectFirstVm();

  useEffect(() => {
    selectFirstVm();
  }, [vms]);

  useEffect(() => {
    // TODO: check how, even after commenting this
    // out, the summary display is till working
    console.log("selectedVm: ", selectedVm);
    if (selectedVm) {
      setRecommendations(selectedVm.recommendations);
    } else {
      setRecommendations(null);
    }
  }, [selectedVm]);

  useEffect(() => {
    getAllVmsAsync();
  }, [selectedRecomType]);

  useEffect(() => {
    if (selectedAccessKey) {
      setSelectedVm(null);
      setRecommendations(null);

      (async function () {
        // TODO: Show loading when this API call is in progress
        const result = await getAllVmsAsync(
          selectCloudProvider,
          selectedRecomType,
          selectedAccessKey
        );
        console.log("getAllVmsAsync: ", result);
        const vms = result?.data;
        if (vms && vms?.length > 0) {
          // set first VM selected otherwise the UI for
          // recommendations looks empty.
          const firstVmKey = vms[0];
          const firstVm = vms[firstVmKey];
          setSelectedVm(firstVm);
        }
      })();
    }
  }, [selectCloudProvider, selectedAccessKey]);

  useEffect(() => {
    refetch(selectCloudProvider);
  }, [refetch, selectCloudProvider]);

  const handleVmClick = (vm) => {
    setSelectedVm(vm);
    const newFilterModel = { items: [] };
    if (vm.vCPUs)
      newFilterModel.items.push({
        columnField: "vCPUs",
        operatorValue: "equals",
        value: vm.vcpu,
      });
    if (vm.memory)
      newFilterModel.items.push({
        columnField: "memory",
        operatorValue: "equals",
        value: vm.memory,
      });
    setFilterModel(newFilterModel);
  };

  const onCloudChanged = (cloudProvider) => {
    setSelectedAccessKey(null);
    setRecommendations(null);
    // setSelectedVm(vms && vms.length > 0 ? vms[0] : null);
    setSelectedVm(null);
    setSelectedRecommendType(ADDITIONAL_RECOMMENDATION_TYPE);
    setSelectCloudProvider(cloudProvider);
    console.log("XXX vms.onCloudChanged: ", vms);
  };

  const onRecommendationTypeChanged = (event) => {
    const recommendation = event.target.value;
    console.log("recommendation: ", recommendation);
    setSelectedVm(null);
    setSelectedRecommendType(event.target.value);
  };

  const handleAccessKeyChanged = (event) => {
    const value = event.target.value;
    console.log("selected.value: ", value);
    // setRecommendations(null);
    // setSelectedVm(vms && vms.length > 0 ? vms[0] : null);
    setSelectedAccessKey(value);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: 2,
        }}
      >
        <Box display="flex" gap={2} justifyContent="center">
          <CloudProviderCard
            logo={LogoAWS}
            alt="AWS Logo"
            isSelected={selectCloudProvider === CLOUD_PROVIDER_AWS}
            onClick={() => onCloudChanged(CLOUD_PROVIDER_AWS)}
            theme={theme}
          />
          <CloudProviderCard
            logo={LogoAzure}
            alt="Azure Logo"
            isSelected={selectCloudProvider === CLOUD_PROVIDER_AZURE}
            onClick={() => onCloudChanged(CLOUD_PROVIDER_AZURE)}
            theme={theme}
          />
          <CloudProviderCard
            logo={LogoGCP}
            alt="GCP Logo"
            isSelected={selectCloudProvider === CLOUD_PROVIDER_GCP}
            onClick={() => onCloudChanged(CLOUD_PROVIDER_GCP)}
            theme={theme}
          />
          <CloudProviderCard
            logo={LogoOci}
            alt="Oracle Logo"
            isSelected={selectCloudProvider === CLOUD_PROVIDER_OCI}
            onClick={() => onCloudChanged(CLOUD_PROVIDER_OCI)}
            theme={theme}
          />
        </Box>
        <Grid
          container
          direction="row"
          gap={4}
          sx={{
            width: "40%",
          }}
        >
          <Grid
            item
            laptop
            sx={{
              textAlign: "center",
            }}
          >
            <FormControl fullWidth margin="normal">
              {isError ? (
                <Alert severity="warning" sx={{ my: 1 }}>
                  {error}
                </Alert>
              ) : areAccessKeyLoading ? (
                <Box
                  sx={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress variant="indeterminate" />
                </Box>
              ) : accessKeys.data?.length === 0 ? (
                <Box
                  sx={{
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <h3>
                    {/* No access keys found. Please <SolidButton>Add</SolidButton>{" "}
                    Access Keys and check again */}
                    No access keys found.{" "}
                  </h3>
                  <SolidButton
                    onClick={() => {
                      history.push(ROUTE_ADD_NEW_CLOUD_PROVIDER);
                    }}
                  >
                    Add Access Keys
                  </SolidButton>{" "}
                </Box>
              ) : (
                <>
                  {selectedAccessKey && (
                    <Select
                      value={selectedAccessKey}
                      onChange={handleAccessKeyChanged}
                      sx={SelectStyle}
                    >
                      {accessKeys?.data?.map((account) => (
                        <MenuItem key={account.id} value={account.id}>
                          {account.key_label
                            ? account.key_label
                            : `(${
                                showFirstAndLastNChars(account?.key_id, 5) ??
                                "NA"
                              }) (${
                                showFirstAndLastNChars(
                                  account?.key_secret,
                                  6
                                ) ?? "NA"
                              })`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box gap={2} py={2} sx={{ display: "flex" }}>
        <Box
          flex={1}
          sx={{
            minHeight: "820px",
            overflow: "auto",
            scrollBehavior: "smooth",
            scrollbarWidth: "none",
            flexDirection: "column",
            display: "flex",
            minWidth: "400px",
            gap: 2,
          }}
        >
          {selectedAccessKey && (
            <SummaryViewCard
              selectCloudProvider={selectCloudProvider}
              selectedAccessKey={selectedAccessKey}
            />
          )}
          {areVmsLoading ? (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : vms === null ? (
            <Box>
              <h3>Select an Access Key </h3>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Card>
                <CardHeader
                  sx={{
                    display: "flex",
                    overflow: "hidden",
                    "& .MuiCardHeader-content": {
                      overflow: "hidden",
                    },
                  }}
                  title={`${getCloudProviderName(
                    selectCloudProvider
                  )} VMs’ List `}
                />
                <Box
                  sx={{
                    gap: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <SearchInput
                    onSearch={(input) => {
                      console.log("input: ", input);
                      setSelectedVm(null);
                      setSearchQuery(input);
                    }}
                  />
                  <Select
                    labelId="project-select-label"
                    value={selectedRecomType}
                    label="Select Project"
                    onChange={onRecommendationTypeChanged}
                    sx={SelectStyle}
                  >
                    {RECOMMENDATION_TYPES.map((recoType) => (
                      <MenuItem key={recoType.type} value={recoType.type}>
                        {recoType.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {isVmsError ? (
                    <Alert severity="error" sx={{ my: 1 }}>
                      {vmsError}
                    </Alert>
                  ) : vms?.length === 0 ? (
                    <Alert severity="warning" sx={{ my: 1 }}>
                      {DEFAULT_MESSAGE_EMPTY}
                    </Alert>
                  ) : (
                    <Box
                      sx={{
                        height: "100%",
                        overflow: "scroll",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      {!searchQuery &&
                        vms?.map((vm) => (
                          <RecommendationCard
                            vm={vm}
                            onClick={handleVmClick}
                            selectedVm={selectedVm}
                            recomType={selectedRecomType}
                          />
                        ))}
                      {searchQuery &&
                        searchQuery.length > 0 &&
                        vms?.map((vm) => {
                          const sanitizedSearchQuery = searchQuery
                            .toLowerCase()
                            .trim();
                          if (
                            vm.instance_name
                              .toLowerCase()
                              .includes(sanitizedSearchQuery) ||
                            vm.machine_type
                              .toLowerCase()
                              .includes(sanitizedSearchQuery)
                          ) {
                            return (
                              <RecommendationCard
                                vm={vm}
                                onClick={handleVmClick}
                                selectedVm={selectedVm}
                              />
                            );
                          }
                        })}
                    </Box>
                  )}
                </Box>
              </Card>
            </Box>
          )}
        </Box>
        <Box
          flex={2.5}
          style={{
            backgroundColor: "#F5F5F5",
            alignSelf: "stretch",
            alignItems: "top",
            justifyContent: "space-between",
            display: "flex",
            padding: "0px",
            overflow: "auto",
          }}
        >
          {/* This par of code is kept like this
          coz the server keeps changing API responses.
          Earlier, the recommendations had a separate API,
          but now that data is available right in the vm itself. */}
          {areVmsLoading ? (
            <>
              <Skeleton
                animation={"wave"}
                variant="rectangular"
                height="400px"
              />
              {/* <CircularProgress variant="indeterminate" /> */}
            </>
          ) : recommendations ? (
            <RecommendationSummaryPage
              vm={selectedVm}
              cloudProvider={selectCloudProvider}
              accessToken={selectedAccessKey}
              recommendations={recommendations}
              filterModel={filterModel}
            />
          ) : (
            <>
              <Card
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardContent>
                  <H4>Select a VM to see the recommendations</H4>
                </CardContent>
              </Card>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RecommendationDetails;
