// Single place to define the name of the product
// Tomorrow the name changes, branding or styling changes
// Change here and that should be all.
export const USER_FACING_PRODUCT_NAME = "CloudNudge";

export const CLOUD_PROVIDER_AWS = "aws";
export const CLOUD_PROVIDER_GCP = "gcp";
export const CLOUD_PROVIDER_AZURE = "azure";
export const CLOUD_PROVIDER_OCI = "oci";
export const CLOUD_PROVIDER_ALL = "total";
export const CLOUD_PROVIDER_DEFAULT = CLOUD_PROVIDER_ALL;

export const TREND_UPWARD = "upward";
export const TREND_DOWNWARD = "downward";
export const TREND_STABLE = "stable";

export const DURATION_KEY_LAST7DAYS = "last7Days";
export const DURATION_KEY_LASTMONTH = "last30Days";
export const DURATION_KEY_LASTQUARTER = "last3Months";
export const DURATION_KEY_LASTYEAR = "lastYear";

export const STANDARD_DURATIONS = [
  {
    duration: DURATION_KEY_LAST7DAYS,
    label: "7 Days",
  },
  {
    duration: DURATION_KEY_LASTMONTH,
    label: "30 Days",
  },
  {
    duration: DURATION_KEY_LASTQUARTER,
    label: "3 Months",
  },
  {
    duration: DURATION_KEY_LASTYEAR,
    label: "1 Year",
  },
];

// local storage related keys. Compulsorily window.localStorage.
export const STORAGE_AUTH_TOKEN = "token";
export const STORAGE_USER_INFO = "user_info";

// local caching related keys.
// This also includes caching done by tanstack-react-query
export const QUERY_KEY_RECOMMENDATIONS = "recommendations";
export const QUERY_KEY_ACCOUNT_DETAILS = "account-details";
export const QUERY_KEY_ACCOUNT_SUMMARY = "account-summary";
export const QUERY_KEY_ACC_TOKENS = "account-tokens";
export const QUERY_KEY_ACCOUNT_SPEND_EFFECIENCY = "account-spend-effeciency";
export const QUERY_KEY_RECOMMENDATION_FILTERS = "recommendations-filters";
export const QUERY_KEY_COST_BY_REGION = "cost-by-region";
export const QUERY_KEY_TOP_ACC_COMPUTE_EFFECIENCY = "top-compute-effeciency";
export const QUERY_KEY_ACCOUNT_COMPUTE_EFFECIENCY =
  "account-compute-effeciency";
export const QUERY_KEY_POSSIBLE_SAVINGS = "possible-savings";
export const QUERY_KEY_MONITORING_CPU = "recommendations-monitoring-cpu";
export const QUERY_KEY_MONITORING_MEMORY = "recommendations-monitoring-memory";

// types of recommendations
export const RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS = "terminate";
export const RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE = "downsize";
export const RECOMMENDATION_TYPE_CHANGING_ARCH = "architecture";
export const RECOMMENDATION_TYPE_OPTIMAL = "optimal";
// type 5 is coming soon...

export const STANDARD_RECOMMENDATION_TYPES = [
  {
    type: RECOMMENDATION_TYPE_TERMINATE_IDLE_VMS,
    label: "By Terminating idle VMs",
  },
  {
    type: RECOMMENDATION_TYPE_DOWNSIZING_INSTANCE,
    label: "By Downsizing idle VMs",
  },
  {
    type: RECOMMENDATION_TYPE_CHANGING_ARCH,
    label: "By Changing Architecture",
  },
];
