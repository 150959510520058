import styled from '@emotion/styled/macro';
import React from 'react';
import { Link as BaseLink } from 'react-router-dom';

const LocalLink = styled(BaseLink)`
    text-decoration: none;

    &:focus, &:hover {
        text-decoration: underline;
    }
`;

export const Link = (props) => {
  return (<LocalLink variant="h4" style={{
    // base style goes here
  }} {...props} />);
}